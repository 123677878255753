import { Link } from "gatsby";
import React from "react";

const QualityGurantee = ({
  heading,
  subheading,
  button1,
  button,
  text,
  qualityGuranteeContent,
}) => {
  return (
    <div className="pt-5 sm:px-6 py-5  max-w-6xl mx-auto">
      <div className="flex flex-col items-center justify-center gap-8 text-[#ffffff]">
        <p className="text-[#649DF2] text-center font-semibold leading-6 text-xl font-primary">
          {text}
        </p>
        <h1 className=" font-bold text-4xl md:text-5xl leading-[62px] text-white font-primary text-center">
          {heading}
        </h1>
        <p className="text-center font-opensans text-base md:text-lg font-base leading-7 font-normal  lg:px-24 ">
          {subheading}
        </p>
        <div className="max-w-5xl mx-auto">{qualityGuranteeContent}</div>
        <div className="mt-6 md:flex  text-lg w-full md:w-max  ">
          {" "}
          <div className="w-full md:w-max relative md:right-5 ">
            <Link to="/quote">{button1}</Link>
          </div>
          <div className="w-full md:w-max mt-6  md:mt-0">
            <Link to="/contactus.php">{button}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QualityGurantee;
